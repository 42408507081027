import React, { Fragment, HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import { 
    DateFromString, 
    DateToTimestamp, 
    GetWebsocketUrl, 
    MakeValidURL, 
    OtherDayTimeFromString, 
    ProfileStyles, 
    ScreenColorsDict, 
    SetDict, 
    Sticky, 
    TimeFromString, 
    URLRegExp, 
    UUIDRegExp
} from "../elements/Data";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { 
    AngleRight, 
    Edit, 
    LargeAvatar, 
    NewAvatar, 
    TopBarDesktop,
    Loading, 
} from "../elements/Elements.jsx";
import {  
    ConfirmPopup, 
} from "../elements/Popups";
import { 
    DocWhite, 
    HumansBlack, 
    ListBlack, 
    OkGray,
    OkGreen,
    SendMsgBlack,
    TrashBlack,
} from "../elements/Imgs";
import { ChatsRequest, MessagesRequest, NewOffer, OffersRequest, ProfileRequest, ReactOffer } from "../Requests";
import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { FeedRequestCardDesktop } from "./RequestCards";

const ChatListDesktop= (props: any) => {
    return (
        <div className={props.className}>
            {(
                props.chats?.length ||
                props.chatsRequested?.length ||
                props.chatsOffers?.length
            ) ? 
            <div className={`block b-3 w-flex h-chat ${(props.offerId === null && props.requestedId === null) ? "" : "md-h-8"} y-auto`}
                 style={{padding: 6}}>
                {[
                    ...props.chats, 
                    ...(props.chatsRequested ?? []).map((elem: any, index: number) => SetDict(elem, ["requested_index"], index)),
                    ...(props.chatsOffers ?? []).map((elem: any, index: number) => SetDict(elem, ["offer_index"], index)),
                ].sort((
                    a: {last_message?: {created_at: string | number}, created_at?: string | number}, 
                    b: {last_message?: {created_at: string | number}, created_at?: string | number},
                ) => DateToTimestamp(b.last_message?.created_at ?? b?.created_at ?? 0) - DateToTimestamp(a.last_message?.created_at ?? a?.created_at ?? 0))
                .map((elem: any, index: number) => {return (typeof elem?.requested_index === 'number' ? 
                <Fragment key={index}>
                    {!!index &&
                    <div style={{
                        height: 1,
                    }} className={`w-flex block mb`} data-color={elem.requested_index === props.requestedId ? `transparent` : `light-gray`}></div>}
                    <div className={`block w-flex p-1 clickable row left top nowrap noscroll b-2`} onClick={() => {
                            if (typeof props.setRequestedId === `function`) {
                                props.setRequestedId(elem.requested_index);
                            }
                        }} data-color={elem.requested_index === props.requestedId ? 'light-gray' : null} style={{borderRadius: 20}}> 
                        <NewAvatar size={5} type={elem.pair?.avatar?.style ?? 0} name={elem.pair?.name}
                                avatar={elem.pair?.avatar?.link} className={`circled`}/>
                        <div className={`w-flex my`}>
                            <div className={`row nowrap w-flex top mb`}>
                                <div className={`row nowrap`}>
                                    <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                                    style={{maxWidth: 75}}>
                                        {elem.pair?.name}
                                    </p> 
                                    {!elem?.is_new_requested && 
                                    <div className={`label block btn-circled-16`} data-color={`gray`}>
                                        <p className={`regular`}>{`Pending`}</p>
                                    </div>}
                                </div>
                                <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                    {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                                </p>
                            </div>
                            <div className={`row nowrap w-flex bottom`}>
                                <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: `break-word`,
                                    }}>
                                    {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? (elem?.is_new_requested ? (elem?.is_can_help ? `Hi! I can help you with...` : `I hope you're doing well! I’m looking for …`) :
                                        `Hi, ${(elem.pair?.name + ` `).split(' ')[0]}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`))
                                    .trim().split('\n').map((elem_: string, index: number) => {return (
                                        <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                            {elem_}<br/>
                                        </span>
                                    )})}
                                </p>  
                                {!!elem?.unread &&
                                <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                    <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                                </div>}
                            </div>
                        </div>
                    </div> 
                    <div style={{
                        height: 1, marginBottom: -1, zIndex: 2, position: `relative`,
                    }} className={`w-flex block mb`} data-color={elem.requested_index === props.requestedId ? null : `transparent`}></div>
                </Fragment> : 
                (typeof elem?.offer_index === 'number' ?
                <Fragment key={index}>
                    {!!index &&
                    <div style={{
                        height: 1,
                    }} className={`w-flex block mb`} data-color={elem.offer_index === props.offerId ? `transparent` : `light-gray`}></div>}
                    <div className={`block w-flex p-1 clickable row left top nowrap noscroll b-2`} onClick={() => {
                            if (typeof props.setOfferId === `function`) {
                                props.setOfferId(elem.offer_index);
                            }
                        }} data-color={elem.offer_index === props.offerId ? 'light-gray' : null} style={{borderRadius: 20}}> 
                        <NewAvatar size={5} type={elem.user?.avatar?.style ?? 0} name={elem.user?.name}
                                avatar={elem.user?.avatar?.link} className={`circled`}/>
                        <div className={`w-flex my`}>
                            <div className={`row nowrap w-flex top mb`}>
                                <div className={`row nowrap`}>
                                    <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                                    style={{maxWidth: 70}}>
                                        {elem.user?.name}
                                    </p>  
                                    <div className={`label block btn-circled-16`} data-color={`bright-blue`}>
                                        <p className={`regular`}>{`Help offer`}</p>
                                    </div>
                                </div>
                                <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                    {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                                </p>
                            </div>
                            <div className={`row nowrap w-flex bottom`}>
                                <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: `break-word`,
                                    }}>
                                    {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? (elem?.is_new_requested ? (elem?.is_can_help ? `Hi! I can help you with...` : `I hope you're doing well! I’m looking for …`) :
                                        `Hi, ${(elem.user?.name + ` `).split(' ')[0]}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`))
                                    .trim().split('\n').map((elem_: string, index: number) => {return (
                                        <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                            {elem_}<br/>
                                        </span>
                                    )})}
                                </p>  
                                {!!elem?.unread &&
                                <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                    <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                                </div>}
                            </div>
                        </div>
                    </div> 
                    <div style={{
                        height: 1, marginBottom: -1, zIndex: 2, position: `relative`,
                    }} className={`w-flex block mb`} data-color={elem.offer_index === props.offerId ? null : `transparent`}></div>
                </Fragment> :
                <Fragment key={index}>
                    {!!index &&
                    <div style={{
                        height: 1,
                    }} className={`w-flex block mb`} data-color={elem?.id === props.chatId ? `transparent` : `light-gray`}></div>}
                    <div className={`block w-flex p-1 row left top nowrap noscroll b-2 clickable`} onClick={() => {
                            if (typeof props.setChatId === `function`) {
                                props.setChatId(elem?.id);
                            }
                        }} data-color={elem?.id === props.chatId || elem?.user_id === props.chatId ? 'light-gray' : null} style={{borderRadius: 20}}> 
                        <NewAvatar size={5} type={elem?.avatar?.style ?? 0} name={elem?.name}
                                avatar={elem?.avatar?.link} className={`circled`}/>
                        <div className={`w-flex my`}>
                            <div className={`row nowrap w-flex top mb`}>
                                <p data-size={6} className={`semibold seminarrow ellipsis`} data-color={`black`}
                                   style={{maxWidth: 120}}>
                                    {elem.name}
                                </p>  
                                <p data-size={7} className={`noshrink seminarrow`} data-color={'black-40-opacity'}>
                                    {DateFromString(elem[`last_message`]?.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem[`last_message`]?.created_at) : OtherDayTimeFromString(elem[`last_message`]?.created_at)}
                                </p>
                            </div>
                            <div className={`row nowrap w-flex bottom`}>
                                <p data-size={7} className={`w-flex`} data-color={`black`} data-lines={2} style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: `break-word`,
                                    }}>
                                    {(elem['last_message']?.content ?? `Hi, ${(elem?.name + ` `).split(' ')[0]}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`)
                                    .trim().split('\n').map((elem_: string, index: number) => {return (
                                        <span key={index} data-color={elem['last_message']?.content ? null : `black-40-opacity`}>
                                            {elem_}<br/>
                                        </span>
                                    )})}
                                </p>  
                                {!!elem?.unread &&
                                <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`green`}>
                                    <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                                </div>}
                            </div>
                        </div>
                    </div> 
                    <div style={{
                        height: 1, marginBottom: -1, zIndex: 2, position: `relative`,
                    }} className={`w-flex block mb`} data-color={elem?.id === props.chatId ? null : `transparent`}></div>
                </Fragment>))})}
            </div> : 
            <div className={`skeleton p-3 b-3 h-chat w-flex`}></div>}
        </div>)
}

const OfferListDesktop= (props: any) => {
    return (
        <div className={props.className}>
            {!!props.offers && props.offers?.length ? 
            <div className={`block p-3 b-3 w-flex h-chat ${props.offerId === null ? "" : "md-h-8"} y-auto`}>
                {[...props.offers].map((elem: any, index: number) => {return (<Fragment key={index}>
                    {!!index &&
                    <div style={{
                        height: 1,
                    }} className={`w-flex block mb`} data-color={index === props.offerId ? `transparent` : `light-gray`}></div>}
                    <div className={`block w-flex-p-1 neg-mx-1 pl-1 pr-1 clickable row left gap-2 top nowrap noscroll py-2 b-2`} onClick={() => {
                            if (typeof props.setOfferId === `function`) {
                                props.setOfferId(index);
                            }
                        }} data-color={index === props.offerId ? 'light-gray' : null}> 
                        <NewAvatar size={4} type={(props.requested ? elem.pair : elem.user)?.avatar?.style ?? 0} name={(props.requested ? elem.pair : elem.user)?.name}
                                avatar={(props.requested ? elem.pair : elem.user)?.avatar?.link}/>
                        <div className={`w-flex`}>
                            <div className={`row nowrap w-flex top`}>
                                <p className={`semibold seminarrow text-6 mr-2`} data-color={`black`}>
                                    {(props.requested ? elem.pair : elem.user)?.name}
                                </p>  
                                <p className={`text-6 noshrink seminarrow`} data-color={'black-40-opacity'}>
                                    {DateFromString(elem.created_at) === DateFromString(Date.now() / 1000) ? TimeFromString(elem.created_at) : OtherDayTimeFromString(elem.created_at)}
                                </p>
                            </div>
                            <div className={`row nowrap w-flex bottom`}>
                                <p className={`text-6 w-flex`} data-color={`black`} data-lines={2} style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: `break-word`,
                                    }}>
                                    {(elem?.items[(elem?.items?.length ?? 1) - 1]?.text ?? (elem?.is_new_requested ? (elem?.is_can_help ? `Hi! I can help you with...` : `I hope you're doing well! I’m looking for …`) :
                                        `Hi, ${((props.requested ? elem.pair : elem.user)?.name + ` `).split(' ')[0]}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`))
                                    .trim().split('\n').map((elem_: string, index: number) => {return (
                                        <span key={index} data-color={elem?.items[(elem?.items?.length ?? 1) - 1]?.text ? null : `black-40-opacity`}>
                                            {elem_}<br/>
                                        </span>
                                    )})}
                                </p>  
                                {!!elem?.unread &&
                                <div className={`block min-w-3 h-3 b-2 centered px-1 noshrink`} data-color={`blue`}>
                                    <p className={`narrow text-6`} data-color={'white'}>{elem?.unread}</p>
                                </div>}
                            </div>
                        </div>
                    </div> 
                </Fragment>)})}
            </div> : 
            <div className={`skeleton p-3 b-3 h-chat w-flex`}></div>}
        </div>)
}

const ChatFieldDesktop= (props: any) => {
    const [ cookie ] = useCookies(['user_id_cookie']);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    
    interface Message {
        id: string,
        from_user: string,
        is_read: boolean,
        content: string,
        created_at: string | number,
        reply_to: {
            text: string,
            created_at: number | string,
            user_name?: string,
            user_occupation?: string,
            user_avatar: {
                link?: string,
                style?: number,
            },
        }
    }

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ wsEstablished, setWsEstablished ] = useState<boolean>(false);
    const [ firstLoading, setFirstLoading ] = useState<boolean>(true);
    const [ waitAcceptOrReject, setWaitAcceptOrReject ] = useState<boolean>(false);

    const [ ws, setWs ] = useState<WebSocket | null>(null);
    const [ receivedMessage, setReceivedMessage ] = useState<any>({});
    const [ messages, setMessages ] = useState<Message[]>([]);
    const [ newMessage, setNewMessage ] = useState<string>(''); 
    const [ newChatId, setNewChatId ] = useState<string | null>(null);   

    const [ interlocutor, setInterlocutor ] = useState<any>();
    const [ profile, setProfile ] = useState<any>();

    const [ controlMessage, setControlMessage ] = useState<any>(null);
    const [ editMessageId, setEditMessageId ] = useState<string | null>(null);
    const [ deleteMessageId, setDeleteMessageId ] = useState<string | null>(null);
    const [ controlXY, setControlXY ] = useState<number[]>([-1000, -1000]);

    const [ newRequestedWait, setNewRequestedWait ] = useState<boolean>(false);

    const chatField = useRef(null);
    const chatInput = useRef(null);

    const OnDocClick: EventListenerOrEventListenerObject = () => {
        setControlMessage(null);
        setEditMessageId(null);
        setDeleteMessageId(null);
    }

    const FindControlBtnsPosition = (clientClick: number[]) => {
        if (window.innerWidth < clientClick[0] + 160) {
            if (window.innerHeight < clientClick[1] + 70) {
                return [clientClick[0] - 160, clientClick[1] - 80]
            } else {
                return [clientClick[0] - 160, clientClick[1]]
            }
        } else {
            if (window.innerHeight < clientClick[1] + 70) {
                return [clientClick[0] + 15, clientClick[1] - 80]
            } else {
                return [clientClick[0] + 15, clientClick[1]]
            }
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!!props.chats[props.chatId]?.is_new_requested) {
            setNewRequestedWait(true);
            NewOffer({
                user_id: cookie[`user_id_cookie`],
                pair_id: context.newChatOffer?.pair?.id,
                card_id: context.newChatOffer?.refer_to_request?.id ?? null,
                text: newMessage,
            }, () => {
                setNewRequestedWait(false);
                props.setRequested((val: any[]) => SetDict(val, [props.chatId], {
                    created_at: Date.now() / 1000,    
                    items: [{
                        created_at: Date.now() / 1000,
                        card: context.newChatOffer?.refer_to_request,
                        text: newMessage,
                    }],
                    pair: context.newChatOffer?.pair,
                    user: context.newChatOffer?.user,
                }))
                setNewMessage("");
                context.setNewChatOffer(undefined);
            }, () => { setNewRequestedWait(false) })
        } else {
            if (props.requested && editMessageId) {
                setNewRequestedWait(true);
                NewOffer({
                    user_id: cookie[`user_id_cookie`],
                    pair_id: interlocutor?.id,
                    card_id: controlMessage?.card?.id,
                    text: newMessage,
                }, () => {
                    setNewRequestedWait(false);
                    const itemIdx = (props.chats[props.chatId]?.items ?? []).findIndex((val: any) => val?.card?.id === controlMessage?.card?.id)
                    if (itemIdx > -1) {
                        props.setRequested((val: any[]) => SetDict(val, [props.chatId, "items", itemIdx, "text"], newMessage))
                    }
                    setNewMessage("");
                    setControlMessage(null);
                    setEditMessageId(null);
                    setDeleteMessageId(null);
                }, () => { setNewRequestedWait(false) })
            } else if (newMessage.trim() && ws?.readyState === 1) {
                setControlMessage(null);
                if (editMessageId) {
                    ws.send(JSON.stringify({
                        'event_type': 'edit',
                        'message_id': editMessageId,
                        'edited_message': newMessage.trim(),
                    }));
                    setEditMessageId(null);
                } else {
                    ws.send(JSON.stringify({
                        'event_type': 'send',
                        'message': newMessage.trim(),
                        'refer_to': (props.chats[props.chatId] ?? props.chats.find((val: any) => val?.id === props.chatId))?.refer_to_request?.id ?? null,
                    }));
                }
                setNewMessage('');
                context.setNewChatOffer(null);
            }
        }
    }

    // useEffect(() => {  
    //     if (document.querySelector(`.chat-field`)) {      
    //         (document.querySelector(`.chat-field`) as HTMLDivElement).style.height = `calc(100% - ${(document.querySelector(`#chat-input-block`) as HTMLDivElement).scrollHeight + 86}px)`;
    //     }
    // }, [newMessage, props.requested])

    useEffect(() => {
        if (wsEstablished) {
            const unreadMessages: Message[] = messages.filter(val => !val.is_read && val.from_user !== cookie['user_id_cookie']) as Message[];
            if (
                ws?.readyState === 1 && 
                unreadMessages.length && 
                globalContext.lastActivityTimestamp > Number(new Date()) - 60000
            ) {             
                ws.send(JSON.stringify({
                    'event_type': 'read',
                    'message_id': unreadMessages[unreadMessages.length - 1].id,
                }));
            }
        }
    }, [wsEstablished, messages[(messages?.length ?? 1) - 1], globalContext.lastActivityTimestamp])

    useEffect(() => {
        if (chatField.current) {
            (chatField.current as HTMLDivElement).scrollTo({
                top: (chatField.current as HTMLDivElement).scrollHeight,
                behavior: firstLoading ? 'instant' as ScrollBehavior: 'smooth' as ScrollBehavior,
            });
        }
        if (messages?.length) {
            setFirstLoading(false);
        } 
    }, [messages[(messages?.length ?? 1) - 1]]);

    useEffect(() => {
        if (chatField.current) {
            (chatField.current as HTMLDivElement).style.overflow = controlMessage ? `hidden` : 'scroll'; 
        }
    }, [controlMessage])

    useEffect(() => {
        if (props.requested) {
            setNewMessage(editMessageId ?? '');
        } else {
            setNewMessage(messages.find(val => val.id === editMessageId)?.content ?? ''); 
        }
        if (chatInput.current && editMessageId) {
            (chatInput.current as HTMLTextAreaElement).focus();
        }
    }, [editMessageId])

    useEffect(() => {
        if (messages && interlocutor && profile) {
            setLoaded(true);
        } 

        if (!props.requested && messages && !messages?.length && interlocutor?.fname) {
            setNewMessage(`Hi, ${interlocutor?.fname}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`)
        } else if (props.requested && props.chats[props.chatId]?.items?.length === 0) {
            setNewMessage(props.chats[props.chatId]?.is_can_help ? `Hi! I can help you with...` : `I hope you're doing well! I’m looking for … and thought you'd be the perfect person to help out!`);
        } else {
            setNewMessage('');
        }       
    }, [interlocutor, profile, messages, props.requested, props.offers])

    useEffect(() => {
        if (messages && messages?.length) {
            setNewMessage("");
        }
    }, [props.chat_id])

    useEffect(() => {
        const _profile = JSON.parse(sessionStorage.getItem('profile') ?? '{}')

        if (_profile?.id === cookie[`user_id_cookie`]) {
            setProfile(_profile);
        } else {
            ProfileRequest(cookie['user_id_cookie'], setProfile);
        }

        document.addEventListener('click', OnDocClick);
        document.addEventListener('scroll', OnDocClick);

        return () => {
            if (ws) {
                ws.onmessage = null;
                ws.onclose = null;
                ws.onopen = null;
                ws.close();
                setWs(null);
                console.log('Chat closed')
            }

            document.removeEventListener('click', OnDocClick);
            document.removeEventListener('scroll', OnDocClick);
        }
    }, [])  

    useEffect(() => {
        if (receivedMessage['event_type'] === 'send') {                              
            setMessages(val => [...val, receivedMessage['message']]);
        } 
        if (receivedMessage['event_type'] === 'edit') {                              
            setMessages(val => SetDict(val, [val.findIndex(elem => elem?.id === receivedMessage['message_id'])], receivedMessage['message']));
        } 
        if (receivedMessage['event_type'] === 'read') {
            (messages ?? []).forEach((elem, index) => {
                setMessages(val => SetDict(val, [index, 'is_read'], true));
            }) 
        } 
        if (receivedMessage['event_type'] === 'delete') { 
            setMessages(val => val.filter(elem => elem?.id !== receivedMessage['message_id']));
        }         
    }, [receivedMessage])

    useEffect(() => {
        if (ws) {
            ws.onmessage = (e: MessageEvent) => {                              
                const message = JSON.parse(e.data);
                setReceivedMessage(message);  
            }
            ws.onclose = (e: CloseEvent) => {
                setTimeout(() => {
                    setWs(new WebSocket(`${GetWebsocketUrl()}/${props.chatId}`));
                }, 1000)
            }
            ws.onopen = e => {
                setWsEstablished(true);
            }
        } else if (props.chatId && UUIDRegExp.test(props.chatId)) {
            setWs(new WebSocket(`${GetWebsocketUrl()}/${props.chatId}`));
        }
    }, [ws]);

    useEffect(() => {
        setFirstLoading(true);
        setLoaded(false);
        setMessages([]);
        setInterlocutor((props.chats ?? []).find((val: any) => val.id === props.chatId) ?? null);
        setWsEstablished(false);

        if (!props.offers && !props.requested) {
            MessagesRequest(props.chatId, setMessages, setInterlocutor);
        } else if (props.chatId !== Infinity) {
            setInterlocutor(props.requested ? props.chats[props.chatId]?.pair : props.chats[props.chatId]?.user);
        }

        if (ws) {
            ws.onclose = null;
            ws.onmessage = null;
            ws.onopen = null;
            ws?.close();
        }
        if (props.chatId && UUIDRegExp.test(props.chatId)) {
            setWs(new WebSocket(`${GetWebsocketUrl()}/${props.chatId}`));
        }
    }, [props.chatId, props.requested, props.offer])

    useEffect(() => {
        if (profile?.links?.email) {
            Smartlook.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })

            posthog.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })
        }
    }, [profile?.links?.email ?? null])

    useEffect(() => {
        if (newChatId) {  
            context.setTab(0); 
            props.setChatId(newChatId); 
        }
    }, [newChatId])

    return (
        <div className={props.className}>
            {(!!props.chatId && !props.offers && !props.requested) || (props.chatId !== null && props.offers && props.chatId !== Infinity) || (props.chatId !== null && props.requested && props.chatId !== Infinity) ? 
            <div className={`block b-3 h-chat w-flex column`}>
                <div className={`block p-2 w-flex bt-3`} style={{
                    zIndex: 48,
                    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.04)`
                }}> 
                    <div className={`row left nowrap gap-2 w-flex`}>                           
                        {loaded ?
                        <a href={`/profile/${interlocutor?.unique_id ?? interlocutor?.id}?from=chat`} className={`mobile`}>
                            <NewAvatar size={5} type={interlocutor?.avatar?.style ?? 0} name={interlocutor?.name ?? ``}
                                    avatar={interlocutor?.avatar?.link} className={`circled`}/></a> :
                        <div className={`skeleton w-4 h-4 b-1`}></div>}
                        {loaded ?
                        <a href={`/profile/${interlocutor?.unique_id ?? interlocutor?.id}?from=chat&chat_id=${props.chatId}`} className={`mobile col-7`}>
                            <p className={`text-5 semibold`} data-color={`black`}
                                style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}>
                                {interlocutor?.name ?? ``}
                            </p>
                        </a> : 
                        <div className={`skeleton h-3 w-7 b-3`}></div>} 
                    </div>
                </div>
                <div className={`column bottom w-flex h-flex y-auto chat-field desktop`}>
                    <div className={`w-flex p-3`} ref={chatField} style={{paddingBottom: 10}}>
                        {!props.offers && !props.requested ? 
                        (!!messages && messages.map((elem, index) => {return (
                        <div className={'w-flex'} key={index}>
                            {(!index || DateFromString(elem['created_at']) !== DateFromString(messages[index - 1]['created_at'])) && 
                            <div className={`w-flex my-2`}>
                                <p className={'text-6 center'} data-color={'black-40-opacity'}>{DateFromString(elem['created_at'])}</p>
                            </div>}
                            <div className={`block row left top w-flex-p-2 p-2 b-2 neg-mx-2 nowrap noscroll gap-2 message`}
                                 data-color={controlMessage === elem.id ? 'black-5-opacity' : 'transparent'}>
                                {elem.from_user === cookie[`user_id_cookie`] ?
                                <NewAvatar name={profile?.name} type={profile?.avatar?.style} size={4} avatar={profile?.avatar?.link}/> :
                                <NewAvatar name={interlocutor?.name} type={interlocutor?.avatar?.style} size={4} avatar={interlocutor?.avatar?.link}/>}
                                <div className={`b-2`} style={{width: `calc(100% - 54px)`}} 
                                    onContextMenu={cookie[`user_id_cookie`] === elem.from_user ? e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setControlXY(FindControlBtnsPosition([e.clientX, e.clientY]))
                                        setControlMessage((val: any) => val ? null : elem.id); 
                                    } : undefined}>                                    
                                    <div className={`row nowrap noscroll mb-1`}>
                                        <p data-size={6} data-color={`black-40-opacity`}>
                                            <span data-size={6} className={`semibold mr-1`} data-color={`black`}>
                                                {(elem.from_user === cookie[`user_id_cookie`] ? profile : interlocutor)?.name}
                                            </span>                                         
                                            {TimeFromString(elem['created_at'])}
                                        </p>
                                        
                                        {elem[`from_user`] === cookie['user_id_cookie'] &&
                                        <div className={`w-3 h-3 b-2 centered`}>
                                            <img className={`w-2`} src={elem[`is_read`] ? OkGreen : OkGray} alt={`v`}></img>
                                        </div>}
                                    </div>
                                    <p className={`text-6 mb-1`} style={{
                                        overflowWrap: 'break-word',
                                    }} data-color={`black`}>
                                        {(elem?.content ?? '').trim().split('\n').map((elem_, index) => {return (<Fragment key={index}>
                                            {elem_.split(URLRegExp).filter(elem => !!elem).map((elem__, index__) => {return (
                                                URLRegExp.test(elem__) ?
                                                <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                                                    fontWeight: 400,
                                                    position: `relative`,
                                                    zIndex: 2,
                                                }} href={MakeValidURL(elem__)} target={`_blank`}>
                                                    <span data-color={`blue`}>{elem__}</span>
                                                </a> :
                                                <span key={index__} style={{
                                                    fontWeight: 400,
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {elem__}
                                                </span>
                                            )})}
                                            <br/>
                                        </Fragment>)})}
                                    </p>
                                    {!!elem.reply_to &&
                                    <FeedRequestCardDesktop request={elem.reply_to} className={`max-w-9`} onboardingV={true}/>}
                                </div>
                            </div>
                        </div>)})) : 
                        (!!props.chats[props.chatId]?.items &&
                        props.chats[props.chatId]?.items.map((elem: any, index: number) => {return (    
                        <div className={'w-flex'} key={index}>
                            {(!index || DateFromString(elem['created_at']) !== DateFromString(props.chats[props.chatId]?.items[index - 1]['created_at'])) && 
                            <div className={`w-flex my-2`}>
                                <p className={'text-6 center'} data-color={'black-40-opacity'}>{DateFromString(elem?.created_at)}</p>
                            </div>}
                            <div className={`block row left top w-flex-p-2 p-2 b-2 neg-mx-2 nowrap noscroll gap-2 message`}
                                 data-color={(controlMessage && controlMessage?.card?.id === elem?.card?.id) ? 'black-5-opacity' : 'transparent'}>
                                <NewAvatar name={props.chats[props.chatId]?.user?.name} type={props.chats[props.chatId]?.user?.avatar?.style} size={4} 
                                           avatar={props.chats[props.chatId]?.user?.avatar?.link}/>
                                <div className={`b-2`} style={{width: `calc(100% - 54px)`}}>                                    
                                    <div className={`row nowrap noscroll mb-1`}>
                                        <p data-size={6} data-color={`black-40-opacity`}>
                                            <span data-size={6} className={`semibold mr-1`} data-color={`black`}>
                                                {props.chats[props.chatId]?.user?.name}
                                            </span>                                         
                                            {TimeFromString(elem?.created_at)}
                                        </p>
                                    </div>
                                    <p className={`text-6 mb-1`} style={{
                                        overflowWrap: 'break-word',
                                    }} data-color={`black`} 
                                    onContextMenu={cookie[`user_id_cookie`] === props.chats[props.chatId]?.user?.id ? e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setControlXY(FindControlBtnsPosition([e.clientX, e.clientY]))
                                        setControlMessage((val: string | null) => val ? null : elem); 
                                    } : undefined}>
                                        {(elem?.text ?? '').trim().split('\n').map((elem_: string, index: number) => {return (<Fragment key={index}>
                                            {elem_.split(URLRegExp).filter((elem: string) => !!elem).map((elem__: string, index__: number) => {return (
                                                URLRegExp.test(elem__) ?
                                                <a key={index__} onClick={e => { e.stopPropagation() }} style={{
                                                    fontWeight: 400,
                                                    position: `relative`,
                                                    zIndex: 2,
                                                }} href={MakeValidURL(elem__)} target={`_blank`}>
                                                    <span data-color={`blue`}>{elem__}</span>
                                                </a> :
                                                <span key={index__} style={{
                                                    fontWeight: 400,
                                                    overflowWrap: 'break-word',
                                                }}>
                                                    {elem__}
                                                </span>
                                            )})}
                                            <br/>
                                        </Fragment>)})}
                                    </p>
                                    {!!elem?.card &&
                                    <FeedRequestCardDesktop request={elem?.card} className={`max-w-9`} onboardingV={true}/>}
                                </div>
                            </div>
                        </div>)}))}
                    </div>
                </div>
                <div className={`block w-flex p-2 bb-3`}
                    style={{
                        boxShadow: `0px -1px 2px 0px rgba(0, 0, 0, 0.04)`,
                        zIndex: 48,
                    }} id={`chat-input-block`} hidden={props.requested && !editMessageId && !props.chats[props.chatId]?.is_new_requested && false}>
                    {!!(props.chats[props.chatId] ?? props.chats.find((val: any) => val?.id === props.chatId))?.refer_to_request &&
                    <FeedRequestCardDesktop request={(props.chats[props.chatId] ?? props.chats.find((val: any) => val?.id === props.chatId))?.refer_to_request} className={`max-w-9 mb-2`} onboardingV={true}/>}
                    {!props.offers ? ((!props.requested || props.chats[props.chatId]?.is_new_requested || editMessageId || true) && <>
                    <form className={`block b-2 row bottom nowrap nogap noscroll`} data-color={`light-gray`}
                        onSubmit={e => {
                            e.preventDefault();
                            onSubmit(e);
                        }} onKeyUp={() => {}}>
                        <div className={`chat-input desktop`}>
                            <p className={`w-flex`}>
                                {newMessage.split('\n').map((elem: string, index: number) => {return (<Fragment key={index}>
                                    {elem}<br/>
                                </Fragment>)})}
                            </p>                
                            <textarea className={`block b-2 w-flex h-flex`} data-size={6} ref={chatInput}
                                placeholder={`Message`} value={newMessage} 
                                onChange={e => { 
                                    setNewMessage(e.target.value); 
                                }} onKeyUp={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (!e.ctrlKey && !e.shiftKey) {
                                            e.preventDefault();
                                            onSubmit(e);
                                        }
                                    }
                                }} onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (e.ctrlKey || e.shiftKey) {
                                            const caret = (e.target as HTMLTextAreaElement).selectionStart;
                                            (e.target as HTMLTextAreaElement).setRangeText(`\n`, caret, caret, 'end');
                                            setNewMessage((e.target as HTMLTextAreaElement).value);
                                        }
                                    }
                                }} onClick={e => {
                                    if (editMessageId) {
                                        e.stopPropagation();
                                    }
                                }}></textarea>
                        </div>
                        {!!newMessage && (newRequestedWait ?
                        <div className={`py-2 pr-2 row`}>
                            <Loading color={`black`} width={24} height={24}/>
                        </div> :
                        <button type={`submit`} className={`h-3 my-2`} onClick={e => { e.stopPropagation() }}
                                disabled={props.chats[props.chatId]?.items?.length > 0 && !editMessageId} style={{
                                    opacity: (props.chats[props.chatId]?.items?.length > 0) ? 0.4 : 1,
                                }}>
                            <img src={SendMsgBlack} alt={``} className={`w-3 h-3 mr-2`}></img>
                        </button>)}
                    </form> 
                    {(context.profile?.subscription?.is_subscription_active === false && !!newMessage) ? 
                    <p data-size={7} data-color={`coral`} className={`pt pl-2`}>
                        {`You cannot send messages until your subscription is not active.`}
                    </p> :                   
                    (props.chats[props.chatId]?.items?.length > 0 && !!newMessage &&
                    <p data-size={7} data-color={`coral`} className={`pt pl-2`} hidden={!!editMessageId}>
                        {`You can only send one message. Please wait until the person accepts the connection.`}
                    </p>)}</>) : 
                    <div className={`row left nowrap`}>
                        <button className={`block btn-rectangle-40 desktop w-8`} data-color={`black`}
                                onClick={() => {
                                    setWaitAcceptOrReject(true);
                                    ReactOffer({
                                        user_id: props.chats[props.chatId]?.user?.id,
                                        pair_id: cookie[`user_id_cookie`],
                                        card_id: props.chats[props.chatId]?.items[0]?.card?.id ?? null,
                                        action: `accept`,
                                    }, setNewChatId, () => {
                                        setWaitAcceptOrReject(false);
                                        props.setOfferId(Infinity);
                                        OffersRequest(cookie[`user_id_cookie`], props.setOffers);
                                        ChatsRequest(cookie[`user_id_cookie`], props.setChats);
                                    }, () => { setWaitAcceptOrReject(false) })
                                }} disabled={waitAcceptOrReject}>
                            <p className={`semibold center`}>{`Accept and answer`}</p>
                        </button>
                        <button className={`block btn-rectangle-40 desktop w-8`} data-color={`light-gray`}
                                onClick={() => {
                                    setWaitAcceptOrReject(true);
                                    ReactOffer({
                                        user_id: props.chats[props.chatId]?.user?.id,
                                        pair_id: cookie[`user_id_cookie`],
                                        card_id: props.chats[props.chatId]?.items[0]?.card?.id ?? null,
                                        action: `reject`,
                                    }, () => {}, () => {
                                        setWaitAcceptOrReject(false);
                                        props.setOfferId(Infinity);
                                        OffersRequest(cookie[`user_id_cookie`], props.setOffers);
                                    }, () => { setWaitAcceptOrReject(false) })
                                }} disabled={waitAcceptOrReject}>
                            <p className={`semibold center`}>{`Reject`}</p>
                        </button>                        
                        {waitAcceptOrReject &&
                        <Loading color={`black`} width={24} height={24}/>}
                    </div>}
                </div>
            </div> : 
            <div className={`block w-flex h-chat b-3 centered`}>
                <div className={`block b-3 px-2 pt pb`} data-color={`light-gray`}>
                    <p data-size={6} data-color={`black-40-opacity`}>
                        {props.offers ? `Select an offer to answer` : 
                        (props.requested ? `Select an offer to edit` : 
                        `Select a chat to start messaging`)}
                    </p>
                </div>
            </div>}
            
            {!!controlMessage && !editMessageId &&
            <div style={{
                position: 'fixed',
                left: controlXY[0], 
                top: controlXY[1],
                zIndex: 50,
            }} className={`w-7`}>
                {!deleteMessageId ? (props.requested ?
                <button className={`block btn-chat desktop w-7 b-2`} data-color={`black-5-opacity`}
                        onClick={e => { 
                            e.stopPropagation();
                            setEditMessageId(controlMessage?.text);
                        }}>
                    <span data-size={6} data-color={`black`} className={`center`}>{`Edit`}</span>
                </button> : <>
                <button className={`block btn-chat desktop w-7 bt-2`} data-color={`black-5-opacity`}
                        onClick={e => { 
                            e.stopPropagation();
                            setEditMessageId(controlMessage);
                        }}>
                    <span data-size={6} data-color={`black`} className={`center`}>{`Edit`}</span>
                </button>
                <button className={`block btn-chat desktop w-7 bb-2`} data-color={`black-5-opacity`} 
                        onClick={e => { 
                            e.stopPropagation()
                            setDeleteMessageId(controlMessage);
                        }}>
                    <span data-size={6} data-color={`coral`} className={`center`}>{`Delete`}</span>
                </button></>) : 
                <button className={`block btn-chat desktop w-7 b-2`} data-color={`black-5-opacity`} 
                        onClick={e => {
                            e.stopPropagation(); 
                            if (ws?.readyState === 1) {
                                setDeleteMessageId(null);
                                setControlMessage(null);
                                ws.send(JSON.stringify({
                                    'event_type': 'delete',
                                    'message_id': deleteMessageId,
                                }));
                            }
                        }}>
                    <span data-size={6} data-color={`coral`} className={`center`}>{`Delete`}</span>
                </button>}
            </div>}
        </div>)
}

const ChatDesktop = (props: any) => {
    const navigate = useNavigate();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const { id } = useParams();
    const globalContext = useContext(GlobalContext);
    
    interface Message {
        id: string,
        from_user: string,
        is_read: boolean,
        content: string,
        created_at: string | number,
    }
    
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ sticky, setSticky ] = useState<any>(false);
    const [ firstLoading, setFirstLoading ] = useState<boolean>(true);

    const [ ws, setWs ] = useState<WebSocket | null>(null);

    const [ messages, setMessages ] = useState<Message[]>([]);  
    const [ newMessage, setNewMessage ] = useState<string>('');  
    const [ receivedMessage, setReceivedMessage ] = useState<any>({});

    const [ controlMessage, setControlMessage ] = useState<string | null>(null);
    const [ editMessageId, setEditMessageId ] = useState<string | null>(null);
    const [ deleteMessageId, setDeleteMessageId ] = useState<string | null>(null);

    const [ interlocutor, setInterlocutor ] = useState<any>({});
    const [ profile, setProfile ] = useState<any>({});

    const [ largeAvatar, setLargeAvatar ] = useState<any>();

    const chatField = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (chatField.current) {
                (chatField.current as HTMLDivElement).scrollTo({
                    top: (chatField.current as HTMLDivElement).scrollHeight,
                    behavior: firstLoading ? `instant` as ScrollBehavior : `smooth` as ScrollBehavior,
                });
                setTimeout(() => {
                    setFirstLoading(false);
                }, 500)
            }
            const unreadMessages = messages.filter(val => !val['is_read'] && val.from_user !== cookie['user_id_cookie']);
            if (
                ws?.readyState === 1 && 
                unreadMessages.length && 
                globalContext.lastActivityTimestamp > Number(new Date()) - 60000
            ) { 
                console.log('FromMe:', {
                    'event_type': 'read',
                    'message_id': unreadMessages[unreadMessages.length - 1].id,
                })            
                ws.send(JSON.stringify({
                    'event_type': 'read',
                    'message_id': unreadMessages[unreadMessages.length - 1].id,
                }));
            }
        }, 100) 
    }, [messages[(messages?.length ?? 1) - 1], globalContext.lastActivityTimestamp]);

    useEffect(() => {       
        if (messages && !messages.length && interlocutor?.name) {
            setNewMessage(`Hi, ${interlocutor?.fname ?? 'friend'}! I would be happy to meet up. Please let me know if that works for you, or feel free to use my Calendly link...`)
        }
    }, [messages, interlocutor])

    const SetSticky = () => {
        setSticky(!!document.querySelector(`#input-message-form`) && document.querySelector(`#messenger-chat-field`) &&
                    ((document.documentElement.clientHeight - (document.querySelector(`#input-message-form`) as HTMLDivElement).getBoundingClientRect().bottom <= 1) ||
                    document.documentElement.clientHeight - (document.querySelector(`#messenger-chat-field`) as HTMLDivElement).getBoundingClientRect().top - 106 < 600));
        if (document.querySelector(`#messenger-chat-field`)) {
            (document.querySelector(`#messenger-chat-field`) as HTMLDivElement).style.height = `${Math.min(600, document.documentElement.clientHeight - (document.querySelector(`#messenger-chat-field`) as HTMLDivElement).getBoundingClientRect().top - 98)}px`;
        }
    }

    useEffect(() => {
        SetSticky();
        if (document.querySelector(`#messenger-chat-field`)) {
            (document.querySelector(`#messenger-chat-field`) as HTMLDivElement).style.height = `${Math.min(600, document.documentElement.clientHeight - (document.querySelector(`#messenger-chat-field`) as HTMLDivElement).getBoundingClientRect().top - 98)}px`;
        }
    })

    useEffect(() => {
        if (messages && interlocutor && profile) {
            setLoaded(true);
        }
    }, [interlocutor, profile, messages])

    useEffect(() => {
        MessagesRequest(id, setMessages, setInterlocutor);
        ProfileRequest(cookie['user_id_cookie'], setProfile);

        document.addEventListener('scroll', SetSticky);
        window.scrollTo({
            top: 0,
            behavior: `smooth`,
        })

        return () => {
            if (ws) {
                ws.onmessage = null;
                ws.onclose = null;
                ws.close();
                console.log('Chat closed');
            }

            document.removeEventListener('scroll', SetSticky);
        }
    }, [])  

    useEffect(() => {
        if (receivedMessage['event_type'] === 'send') {                              
            setMessages(val => [...val, receivedMessage.message]);
        } 
        if (receivedMessage['event_type'] === 'edit') {                              
            setMessages(val => SetDict(val, [val.findIndex(elem => elem.id === receivedMessage['message_id'])], receivedMessage['message']));
        } 
        if (receivedMessage['event_type'] === 'read') {
            (messages ?? []).forEach((elem, index) => {
                setMessages(val => SetDict(val, [index, 'is_read'], true));
            }) 
        } 
        if (receivedMessage['event_type'] === 'delete') { 
            setMessages(val => val.filter(elem => elem.id !== receivedMessage['message_id']));
        }         
    }, [receivedMessage])

    useEffect(() => {
        if (ws) {
            ws.onmessage = e => {  
                const message = JSON.parse(e.data);
                console.log(message);
                setReceivedMessage(message);
            }
            ws.onclose = e => {
                console.log(e);
                setTimeout(() => {
                    setWs(new WebSocket(`${GetWebsocketUrl()}/${id}`));
                }, 1000)
            }
        } else {            
            setWs(new WebSocket(`${GetWebsocketUrl()}/${id}`));
        }
    }, [ws]);

    const onSubmit = (e: Event) => {
        e.preventDefault();
        if (newMessage.trim() && ws) {
            if (editMessageId) {
                ws.send(JSON.stringify({
                    'event_type': 'edit',
                    'message_id': editMessageId,
                    'edited_message': newMessage.trim(),
                }));
                setEditMessageId(null);
            } else {
                ws.send(JSON.stringify({
                    'event_type': 'send',
                    'message': newMessage.trim(),
                }));
            }
            setNewMessage('');
        }
    }

    useEffect(() => {
        if (profile?.links?.email) {
            Smartlook.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })

            posthog.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })
        }
    }, [profile?.links?.email ?? null])

    return (
    <div className={`body column desktop`}>
        <TopBarDesktop  notification={true} isSettings={!!cookie['user_id_cookie']} profile={profile} setProfile={setProfile}
                        func={() => {
                            navigate(`/profile`);
                        }} toTop={false} className={`w-flex`} color={ProfileStyles[interlocutor?.avatar?.style ?? 0].color}/>

        <div className={`w-flex`}>
            <div className={`block px-5 pb-5 w-flex`} data-color={ProfileStyles[interlocutor?.avatar?.style ?? 0].color} style={Sticky(0, 2) as HTMLAttributes<HTMLDivElement>}>
                <div className={`column left bottom h-flex nogap noscroll`}> 
                    <button className={`btn-circled-24 desktop p-left gap-2 row left noscroll my-3`} onClick={() => {navigate(`/profile/chat`)}}>
                        <div className={`btn-symbol w-3 h-3 back centered`} data-color={ScreenColorsDict[ProfileStyles[interlocutor?.avatar?.style ?? 0].color][0]}>
                            <AngleRight data-color={ScreenColorsDict[ProfileStyles[interlocutor?.avatar?.style ?? 0].color][0]}/>
                        </div>
                        <p data-size={5} data-color={ScreenColorsDict[ProfileStyles[interlocutor?.avatar?.style ?? 0].color][1]}>
                            {`All chats`}
                        </p>
                    </button> 
                    <div className={`row top nowrap nogap w-flex`}>
                        <div className={`column left`}>
                        {loaded ? <>
                            <a href={`/profile/${interlocutor?.unique_id ?? interlocutor?.id}?from=chat&chat_id=${id}`} className={`mobile`}>
                                <p className={`text-2 semibold mr-2`} data-color={ScreenColorsDict[ProfileStyles[interlocutor?.avatar?.style ?? 0].color][1]}>
                                    {interlocutor?.name ?? ``}
                                </p>
                            </a>                            
                            <p className={`text-4 mr-2`} data-color={ScreenColorsDict[ProfileStyles[interlocutor?.avatar?.style ?? 0].color][1]}>
                                {interlocutor.occupation ?? ``}
                            </p>
                        </> : <>
                            <div className={`block p-3 w-9 b-3 my-2`} data-color={`white-10-opacity`}></div>
                            <div className={`block h-3 w-10 b-3`} data-color={`white-10-opacity`}></div>
                        </>}
                        </div>
                        {loaded ?
                        <button className={`pt-2`} id={`avatar-btn-to-large-${interlocutor?.id ?? 0}`} onClick={() => {
                            setLargeAvatar(interlocutor?.avatar?.link ? {
                                link: interlocutor?.avatar?.link,
                                id: interlocutor?.id ?? 0, name: interlocutor?.name, initSize: 6,
                            } : null);
                        }}>
                            <NewAvatar size={6} type={interlocutor?.avatar?.style ?? 0} name={interlocutor?.name ?? ``}
                                    avatar={interlocutor?.avatar?.link}/>
                        </button> :
                        <div className={`block h-6 w-6 b-3 mt-2`} data-color={`white-10-opacity`}></div>}
                    </div>
                </div>
            </div>

            <div className={`row w-flex left top nogap nowrap`}>
                {!!cookie['user_id_cookie'] &&
                <div className={`mt-5 ml-5 mb-5`} data-color={`transparent`} style={Sticky(240 + 58) as HTMLAttributes<HTMLDivElement>}>
                    <button className={`block b-3 p-2 column center w-6 h-6 mb-2 btn-double`} data-color={`white`}
                            onClick={() => { navigate(`/profile/${cookie['user_id_cookie']}`) }}> 
                        {loaded ? 
                        <NewAvatar size={4} type={profile?.avatar?.style ?? 0} name={profile?.name}
                                    avatar={profile?.avatar?.link}/> : 
                        <div className={`skeleton h-4 w-4 b-1`}></div>} 
                        
                        <p className={`text-6 light hover mt-1 custom-hover`} data-color={`black`}>{`Profile`}</p>
                    </button>

                    <button className={`block b-3 p-2 column center w-6 h-6 mb-2 btn-double`} data-color={`white`}
                            onClick={() => { navigate(`/profile/people`) }}> 
                        <div className={`row`}>                       
                            <div className={`row left`}>
                                <img className={`w-3 hover`} src={HumansBlack} alt={`p`}></img>
                            </div>
                            {!!profile?.people_number && 
                            <div className={`block h-3 min-w-3 b-2 centered px-1`} data-color={`green`}>
                                <p className={`text-6 light not-active`}>{profile?.people_number > 99 ? '99+' : profile?.people_number}</p>
                            </div>}
                        </div>
                        <p className={`text-6 light hover mt-1 custom-hover`} data-color={`black`}>{`People`}</p>
                    </button>

                    <button className={`block b-3 p-2 column center w-6 h-6 mb-2 btn-double`} data-color={`blue`}
                            onClick={() => { navigate(`/profile/matches`) }}>  
                        <div className={`row`}>                       
                            <div className={`row left`}>
                                <img className={`w-3 hover`} src={DocWhite} alt={`p`}></img>
                            </div>
                            {!!profile?.chat_number && 
                            <div className={`block h-3 min-w-3 b-2 centered px-1`} data-color={`light-blue`}>
                                <p className={`text-6 light not-active`} data-color={`white`}>{profile?.chat_number > 99 ? '99+' : profile?.chat_number}</p>
                            </div>}
                        </div>
                        <p className={`text-6 light hover mt-1 custom-hover`} data-color={`white`}>{`Matches`}</p>
                    </button>

                    <button className={`block b-3 p-2 column center w-6 h-6 mb-2 btn-double`} data-color={`white`}
                            onClick={() => { navigate(`/profile/feed`) }}> 
                        <div className={`row`}>                        
                            <div className={`row left`}>
                                <img className={`w-3 hover`} src={ListBlack} alt={`p`}></img>
                            </div>
                            {!!profile?.feed_number && 
                            <div className={`block h-3 min-w-3 b-2 centered px-1`} data-color={`coral`}>
                                <p className={`text-6 light not-active`}>{profile?.feed_number > 99 ? '99+' : profile?.feed_number}</p>
                            </div>}
                        </div>
                        <p className={`text-6 light hover mt-1 custom-hover`} data-color={`black`}>{`Feed`}</p>
                    </button>
                </div>}
                
                <div className={`w-flex pt-5 px-5`} style={!!cookie['user_id_cookie'] ? {width: 'calc(100% - 152px)'} : {}}>
                    <div className={`w-flex bt-3 chat-field-shadow`}></div>
                    <div className={`block bt-3 px-3 column bottom chat-field desktop`} id={`messenger-chat-field`} style={{transitionDuration: `0s`}}>
                        <div className={`w-flex py-5`} ref={chatField}>
                        {loaded ? <>
                            {messages && messages.map((elem, index) => {return (<div className={'w-flex'} key={index}>
                                {(!index || DateFromString(elem['created_at']) !== DateFromString(messages[index - 1]['created_at'])) && 
                                <p className={'text-5 semibold center mt-2'} data-color={'gray'}>{DateFromString(elem['created_at'])}</p>}
                                <div className={`row ${elem['from_user'] === cookie['user_id_cookie'] ? 'right' : 'left'} top w-flex nogap nowrap noscroll message mt-2`}
                                     onMouseEnter={elem['from_user'] === cookie['user_id_cookie'] ? () => {
                                        setControlMessage(elem.id);
                                     } : undefined} onMouseLeave={() => { setControlMessage(null) }}>
                                    {(controlMessage === elem.id || deleteMessageId === elem.id) &&
                                    <button className={`btn-double desktop custom-padding`} onClick={e => {
                                        e.stopPropagation();
                                        setDeleteMessageId(elem.id);
                                    }} style={{opacity: 0.5}}>
                                        <img src={TrashBlack} alt={``} className={`mr-1`}></img>
                                    </button>}
                                    {elem['from_user'] === cookie['user_id_cookie'] ? 
                                    null : 
                                    <NewAvatar name={interlocutor?.name ?? ''} type={interlocutor?.avatar?.style ?? 0} size={5}
                                               avatar={interlocutor?.avatar?.link ?? ''} className={`mr-2`}/>}
                                    <div className={`block b-3 p-2 pr-3 pl-3 min-w-9`} data-color={(controlMessage === elem.id || editMessageId === elem.id || deleteMessageId === elem.id) ? 'gray' : 'light-gray'} 
                                         style={{maxWidth: 'calc(100% - 32px)'}}>
                                        <p className={`text-6 regular mb-1`} data-color={'black'} style={{
                                            overflowWrap: 'break-word',
                                        }}>
                                        {(elem?.content ?? '').trim().split('\n').map((elem_, index) => {return (
                                            // <span key={index}>
                                            //     {elem_}<br/>
                                            // </span>
                                            <>
                                                {elem_.split(URLRegExp).filter(elem => !!elem).map((elem__, index__) => {return (
                                                    URLRegExp.test(elem__) ?
                                                    <a key={index__} style={{
                                                        fontWeight: 400,
                                                        position: `relative`,
                                                        zIndex: 2,
                                                    }} href={MakeValidURL(elem__)} target={`_blank`}>
                                                        <span data-color={`blue`}>{elem__}</span>
                                                    </a> :
                                                    <span key={index__} style={{
                                                        fontWeight: 400,
                                                    }}>
                                                        {elem__}
                                                    </span>
                                                )})}
                                                <br/>
                                            </>
                                        )})}
                                        </p>
                                        <div className={`row nowrap noscroll`}>
                                            <div className={`row nogap nowrap noscroll`}>
                                                {(controlMessage === elem.id || editMessageId === elem.id) ? 
                                                <button onClick={e => {
                                                    e.stopPropagation();
                                                    setEditMessageId(elem.id);
                                                    setNewMessage(elem.content);
                                                }} className={`btn-double desktop custom-padding h-3`}>
                                                    <Edit className={'h-3 w-3'} color={`#00000066`}/>
                                                </button> : <>
                                                </>}
                                            </div>
                                            <div className={`row nowrap noscroll`}>
                                                <p className={`text-6 regular`} data-color={`black-40-opacity`}>{TimeFromString(elem[`created_at`])}</p>
                                                {elem['from_user'] === cookie['user_id_cookie'] &&
                                                <div className={`block w-3 h-3 b-2 centered`} data-color={`transparent`}>
                                                    <img className={`w-2`} src={elem[`is_read`] ? OkGreen : OkGray} alt={`v`}></img>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {elem['from_user'] === cookie['user_id_cookie'] &&
                                    <NewAvatar name={profile?.name ?? ''} type={profile?.avatar?.style ?? 0} size={5}
                                                avatar={profile?.avatar?.link ?? ''} className={`ml-2`}/>}
                                </div>
                            </div>)})}
                        </> : <>
                            <div className={`row right top w-flex nogap nowrap noscroll message mt-2`}>
                                <div className={`block b-3 h-6 w-10`} data-color={'gray'}></div>
                                <div className={`block b-2 h-5 w-5 ml-2`} data-color={'gray'}></div>
                            </div>
                            <div className={`row left top w-flex nogap nowrap noscroll message mt-2`}>
                                <div className={`block b-2 h-5 w-5 mr-2`} data-color={'gray'}></div>
                                <div className={`block b-3 h-6 w-10`} data-color={'gray'}></div>
                            </div>
                        </>}
                        </div>
                    </div>

                    <div className={`block p-3 row top nowrap noscroll nogap ${!sticky && 'bb-3'} mb-5`} id={'input-message-form'}
                          data-color={'blue'} style={{...Sticky(-1, 0, 'bottom'), transition: 'border-radius 0.1s ease-in-out', zIndex: 2} as HTMLAttributes<HTMLDivElement>}>
                        <form className={`block w-flex b-2 row bottom nowrap nogap noscroll`} data-color={`light-gray`}
                            onSubmit={e => {
                                    e.preventDefault();
                                    onSubmit(e.nativeEvent);
                            }} onKeyUp={() => {}}>
                            <div className={`chat-input desktop`}>
                                <p className={`w-flex`}>
                                    {newMessage.split('\n').map(elem => {return (<>
                                        {elem}<br/>
                                    </>)})}
                                </p>                
                                <textarea className={`block w-flex h-flex`} data-size={6}
                                    data-color={`transparent`} placeholder={`Message`} value={newMessage} 
                                    onChange={e => { 
                                        setNewMessage(e.target.value); 
                                    }} onKeyUp={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (!e.ctrlKey && !e.shiftKey) {
                                                e.preventDefault();
                                                onSubmit(e.nativeEvent);
                                            }
                                        }
                                    }} onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (e.ctrlKey || e.shiftKey) {
                                                const caret = (e.target as HTMLTextAreaElement).selectionStart;
                                                (e.target as HTMLTextAreaElement).setRangeText(`\n`, caret, caret, 'end');
                                                setNewMessage((e.target as HTMLTextAreaElement).value);
                                            }
                                        }
                                    }} onClick={e => {
                                        if (editMessageId) {
                                            e.stopPropagation();
                                        }
                                    }}></textarea>
                            </div>
                            {!!newMessage &&
                            <button type={`submit`} className={`h-3 my-2`}>
                                <img src={SendMsgBlack} alt={``} className={`w-3 h-3 mr-2`}></img>
                            </button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <LargeAvatar avatar={largeAvatar} setAvatar={setLargeAvatar}/>

        {deleteMessageId &&
        <ConfirmPopup title={`Delete message?`} show={deleteMessageId} setShow={setDeleteMessageId}
                        color_1={'green'} buttonTitle_1={`No`} onClick_1={() => {}} black={true} desktop={true}
                        color_2={'tp'} buttonTitle_2={`Yes`} onClick_2={() => {
                            if (ws?.readyState === 1) {
                                setDeleteMessageId(null)
                                ws.send(JSON.stringify({
                                    'event_type': 'delete',
                                    'message_id': deleteMessageId,
                                }));
                            }
                        }} close={'first'}/>}
    </div>)
}

export {
    ChatListDesktop,
    OfferListDesktop, 
    ChatFieldDesktop,
    ChatDesktop,
}