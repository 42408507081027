import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import getXPath from 'get-xpath';
import { isMobile, deviceDetect } from 'react-device-detect';

import RegistrationMobile from './components/RegistrationMobile';
import RegistrationDesktop from './components/RegistrationDesktop';
import { ProfileMobile } from './components/Profile/ProfileMobile';
import { ProfileDesktop } from './components/Profile/ProfileDesktop';
import { GenericNotFound, GenericOnlyDesktop, InviteNotFound, WaitPage } from './components/GenericNotFound';
import { GenericForbidden, GenericUnauthorized } from './components/GenericForbidden';
import { CardDesktop, CardMobile } from './components/Profile/CardMobile';
import { ChatMobile } from './components/Profile/ChatMobile';
import { InvitePageDesktop, InvitePageMobile } from './components/elements/Invite';
import { SendAnalytics, ViewRequests } from './components/Requests';

import './components/styles/common/common.scss';
import { BlogDesktop, BlogMobile } from './components/Blog';
import { GlobalContext } from './components/elements/Contexts';
import { InfoErrorPopup, InfoPopup, PopupCanHelpMobileLanding, PopupCenterCanHelpLanding } from './components/elements/Popups';
import { AdminDesktop, AdminEditArticle, AdminMobile } from './components/AdminPage';
import { LandingDesktop, LandingMobile } from './components/Landing';
import { UserAgreement } from './components/elements/UserAgreement';

function App() {
    const [ cookie ] = useCookies([`user_id_cookie`]);

    interface Event {
        timestamp: number,
        event: {
            type: string,
            target?: string | null,
            url?: string,
        }
    }

    const [ events, setEvents ] = useState<Event[]>([]);
    const [ lastActivityTimestamp, setLastActivityTimestamp ] = useState<number>(Number(new Date()))
    const [ views, setViews ] = useState<string[]>([]);
    const [ seen, setSeen ] = useState<string[]>(JSON.parse(sessionStorage.getItem("seen_requests") ?? "[]"));
    const [ allowAnalytics, setAllowAnalytics ] = useState(true);
    const [ isTablet, setIsTablet ] = useState<boolean>()
    
    const [ clipBoardOk, setClipboardOk ] = useState<boolean>(false);
    const [ serverError, setServerError ] = useState<boolean>(false);

    const [ canHelpPopup, setCanHelpPopup ] = useState<boolean>(false);
    const [ canHelpRequest, setCanHelpRequest ] = useState<any>();

    const AddEventListeners = () => {
        window.onclick = null;
        window.onsubmit = null;
        window.onfocus = null;

        window.onmousemove = (e) => {
            setLastActivityTimestamp(Number(new Date()));
        }

        [
            {event: `click`, elements: [`BUTTON`, 'A']},
            {event: `submit`, elements: [`FORM`]},
            {event: `focus`, elements: [`INPUT`, 'TEXTAREA']},
        ].forEach((elem) => {
            window.addEventListener(elem.event, e => {
                if (e.target && elem.elements.includes((e.target as Node).nodeName)) {
                    setEvents(val => [
                        ...val,
                        {
                            timestamp: Math.floor(Number(new Date()) / 1000),
                            event: {
                                type: elem.event,
                                target: getXPath(e.target),
                                url: window.location.href,
                            }
                        }
                    ]);
                }
            }, true);
        })
    }

    useEffect(() => {
        if (events?.length > 0) {
            setLastActivityTimestamp(Number(new Date()));
        }
    }, [events])

    useEffect(() => {
        if (allowAnalytics && events.length > 0) {
            setAllowAnalytics(false);
            SendAnalytics(
                cookie[`user_id_cookie`],
                deviceDetect(undefined).osName ?? null,
                events,
                () => {
                    setEvents([]);
                    setTimeout(() => {
                        setAllowAnalytics(true);
                    }, 60000);
                }, () => {
                    setTimeout(() => {
                        setAllowAnalytics(true);
                    }, 60000);
                }
            );  

            if (views?.length) {
                ViewRequests(
                    cookie[`user_id_cookie`],
                    views,
                    () => { setViews([]) },
                )
            }
            sessionStorage.setItem('seen_requests', JSON.stringify(seen));
        }        
    }, [allowAnalytics, events])

    useEffect(() => {
        AddEventListeners();

        window.onload = () => {
            setEvents(val => [
                ...val,
                {
                    timestamp: Math.floor(Number(new Date()) / 1000),
                    event: {
                        type: `popstate`,
                        url: window.location.href,
                    }
                }
            ]);
        }

        window.onbeforeunload = () => {
            window.onclick = null;
            window.onsubmit = null;
            window.onfocus = null;  
            SendAnalytics(
                cookie[`user_id_cookie`],
                deviceDetect(undefined).osName ?? null,
                [...events, {
                    timestamp: Math.floor(Number(new Date()) / 1000),
                    event: {
                        type: `leave_page`,
                        url: window.location.href,
                    }
                }],
            );          
        }
    }, [cookie])

    useEffect(() => {
        document.documentElement.style.overflowX = document.documentElement.clientWidth < 768 ? '' : 'hidden';
        setIsTablet(document.documentElement.clientWidth < 1280);

        window.onresize = e => {
            setIsTablet(document.documentElement.clientWidth < 1280);
            document.documentElement.style.overflowX = document.documentElement.clientWidth < 768 ? '' : 'hidden';
        }

        if (window.location.protocol !== `http:`) {
            document.cookie = "user_id_cookie=0; path=/; max-age=0";
        }

        setInterval(() => {
            document.querySelectorAll(`[data-request-id]`).forEach((elem: Element, index: number) => {
                const id = elem.getAttribute('data-request-id');
                if (id && !seen.includes(id) && elem.getBoundingClientRect().top > 0 && elem.getBoundingClientRect().bottom < window.innerHeight) {
                    // console.warn(views.includes(id), id);
                    setViews(val => val.includes(id) ? val : val.concat([id]));
                    setSeen(val => val.includes(id) ? val : val.concat([id]));
                }
            })
        }, 2000)
    }, [])

    const ChatRedirect = () => {
        const { id } = useParams();
        return <Navigate to={`/profile/chat?chat_id=${id}`} replace />
    }

    return (
        <GlobalContext.Provider value={{
            setViews: setViews,
            isMobile: isMobile, isTablet: isTablet,
            clipBoardOk: clipBoardOk, setClipboardOk: setClipboardOk,
            serverError: serverError, setServerError: setServerError,
            lastActivityTimestamp: lastActivityTimestamp,
            setCanHelpPopup: setCanHelpPopup, setCanHelpRequest: setCanHelpRequest,
        }}>
            <div className={`body-container row center ${isMobile ? `mobile` : 'desktop'} nogap`}>
                <Routes>
                    <Route path="/" element={isMobile ? <RegistrationMobile community={''}/> : <RegistrationDesktop community={''}/>}/>
                    
                    <Route path="/profile" element={isMobile ? <ProfileMobile/> : <ProfileDesktop/>}/>
                    <Route path="/profile/:id" element={isMobile ? <ProfileMobile/> : <ProfileDesktop/>}/>
                    <Route path="/messenger/:id" element={(isMobile || isTablet) ? <ChatMobile/> : <ChatRedirect/>}/>
                    <Route path="/request/:num" element={isMobile ? <CardMobile/> : <CardDesktop/>}/>
                    <Route path="/401" element={<GenericUnauthorized isMobile={isMobile}/>}/>
                    <Route path="/403" element={<GenericForbidden isMobile={isMobile}/>}/>
                    <Route path="/404" element={<GenericNotFound isMobile={isMobile}/>}/>
                    <Route path="/notfound" element={<InviteNotFound isMobile={isMobile}/>}/>
                    <Route path="/invite/:code" element={isMobile ? <InvitePageMobile/> : <InvitePageDesktop/>}/>
                    <Route path="/share/:code" element={isMobile ? <InvitePageMobile share={true}/> : <InvitePageDesktop share={true}/>}/>
                    <Route path="/blog" element={isMobile ? <BlogMobile/> : <BlogDesktop/>}/>
                    <Route path="/blog/:id" element={isMobile ? <BlogMobile/> : <BlogDesktop/>}/>

                    <Route path="/landing" element={isMobile ? <LandingMobile/> : <LandingDesktop/>}/>
                    
                    <Route path="/agreement" element={isMobile ? 
                    <div className={`body top desktop px-5 md-px-3 pt-3`}>
                        <UserAgreement />
                    </div> : 
                    <div className={`body top desktop px-5 md-px-3 pt-3`}>
                        <UserAgreement />
                    </div>} />

                    <Route path="/admin" element={isMobile ? <AdminMobile/> : <AdminDesktop/>}/>
                    <Route path="/admin/blog/edit" element={isMobile ? <GenericOnlyDesktop/> : <AdminEditArticle/>}/>

                    <Route path="/:path" element={ <WaitPage isMobile={isMobile}/> } />
                    <Route path="*" element={ <GenericNotFound isMobile={isMobile}/> } />
                </Routes>
            </div>            

            {clipBoardOk &&
            <InfoPopup show={clipBoardOk} setShow={setClipboardOk} hideBg={true}
                        title={`Link successfully copied to clipboard`}
                        buttonTitle={`Ok, I got it!`} onOk={() => {
                            
                        }} desktop={true} noButton={true}/>}

            {serverError &&
            <InfoErrorPopup show={serverError} setShow={setServerError}
                            title={`Internal Server Error`}
                            buttonTitle={`Reload page`} onOk={() => {
                                window.location.reload()
                            }} mobile={isMobile}/>}              

            {!isMobile ?
            (canHelpPopup &&
            <PopupCenterCanHelpLanding show={canHelpPopup} setShow={setCanHelpPopup} desktop={true} 
                                       request={canHelpRequest}/>) :       
            (canHelpPopup &&
            <PopupCanHelpMobileLanding show={canHelpPopup} setShow={setCanHelpPopup} desktop={true} 
                                       request={canHelpRequest}/>)}
        </GlobalContext.Provider>
    );
}

export default App;
