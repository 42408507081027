import React from "react";
import { NewAvatar, AnimatedLogo } from "./elements/Elements.jsx";
import { useNavigate } from "react-router-dom";
import { OsmosMBlack, OsmosOBlack, OsmosSBlack } from "./elements/Imgs";
import { LogOut } from "./elements/Data";

const GenericForbidden = (props: any) => {
    const navigate = useNavigate();

    return (
        <div className={`block w-flex column ${props.isMobile ? 'pt-4' : 'h-screen'}`} data-color={`gray`} style={{overflow: 'hidden'}}>
            <div className={`row left nogap w-flex nowrap ${props.isMobile ? 'pl-3 neg-mb-5' : 'pl-4 h-7 neg-mb-7 min-w-9'}`}>
                <AnimatedLogo className={`neg-mr`}/>
                <div className={`block mx-${props.isMobile ? 1 : 2}`} data-color={`black-5-opacity`} style={{width: 1, height: props.isMobile ? 30 : 44}}></div>
                <p data-size={6} className={`bold narrow`}>
                    {`Meet the right crowd.`}<br/>
                    {`Get things done.`}
                </p>
            </div>

            {props.isMobile ? <div className={`h-screen column w-flex`}>
                <div className={`w-flex h-9 neg-mb-9`}>
                    <div className={`w-flex row right pr-4 pt-5`}>
                        <NewAvatar type={['light-blue', 'blue']} name={`F V`} size={5}/></div>
                    <div className={`w-flex neg-ml-5`}>
                        <NewAvatar type={['pink', 'coral']} name={`F G`} size={6} className={`neg-ml-3`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar type={['light-pink', 'blue']} name={`I G`} size={6} className={`neg-mr-5`}/></div>
                </div>
                
                <div className={`w-flex px-3 column`} style={{zIndex: 1}}>
                    <h3 className={`mt-3 center`}>{403}</h3>
                    <p data-size={4} className={`center mt-1 mb-3`}>
                        {`Forbidden.`}<br/>{`Access to this resource on the server is denied`}
                    </p>
                    <button className={`block w-7 centered btn-circled-32 mobile`} data-color={`black`} onClick={() => { navigate(-1) }}>
                        <p>{`Go back`}</p>
                    </button>
                </div>

                <div className={`w-flex column bottom h-9 neg-mt-9`}>
                    <div className={`w-flex pl-6 pb-5`}>
                        <NewAvatar type={['white', 'blue']} name={`R K`} size={5} className={`neg-mb-2`}/></div>
                    <div className={`w-flex row right pr-4`}>
                        <NewAvatar type={['light-pink', 'green']} name={`W H`} size={5}/></div>
                    <div className={`w-flex neg-mb-5 pl-3`}>
                        <NewAvatar type={['white', 'yellow']} name={`V N`} size={6}/></div>
                </div>
            </div> : <>
                <div className={`row h-10 neg-mb-10 w-flex nowrap nogap`}>
                    <div className={`w-flex`}>
                        <NewAvatar name={`T N`} type={[`white`, `pink`]} size={6} className={`mb-8 neg-ml-4`}/></div>
                    <div className={`w-flex row center`}>
                        <NewAvatar name={`F W`} type={[`dark-gray`, `coral`]} size={5} className={`mb-8 mr-6`}/></div>
                    <div className={`w-flex h-10 row top right`}>
                        <NewAvatar name={`M G`} type={[`pink`, `green`]} size={6} className={`neg-mt-4 mr-5`}/></div>
                    <div className={`h-9`}>
                        <NewAvatar name={`R K`} type={[`white`, `blue`]} size={5} className={`mb-7`}/></div>
                    <div className={`h-10 row bottom`}>
                        <NewAvatar name={`R K`} type={[`yellow`, `black`]} size={5} className={`mb-7 mr-2`}/></div>
                </div>

                <div className={'column center h-flex'} style={{zIndex: 1}}>
                    <h2 className="mt-3">{403}</h2>
                    <p data-size={3} className={`center mt-2`}>
                        {`Forbidden. Access to this resource on`}<br/>
                        {`the server is denied`}
                    </p>
                    <div className={`row center mt-4`}>
                        <button className={`block w-8 centered btn-circled-40 desktop`} data-color={`black`} onClick={() => { navigate(-1) }}>
                            <p data-color={`white`}>{`Go back`}</p>
                        </button>
                    </div>
                </div>
                
                <div className={`row left h-10 neg-mt-10 w-flex nogap nowrap`}>
                    <div className={`w-flex row center`}>
                        <NewAvatar name={`W H`} type={[`light-pink`, `coral`]} size={5} className={`mt-8`}/></div>
                    <div className={`w-flex h-10 row bottom left`}>
                        <NewAvatar name={`S P`} type={[`light-blue`, `blue`]} size={6} className={`neg-mb-5`}/></div>
                    <div className={`w-flex h-9 row bottom`}>
                        <NewAvatar name={`B D`} type={[`light-blue`, `coral`]} size={5} className={`ml-6`}/></div>
                    <div className={`w-flex h-9 row bottom left`}>
                        <NewAvatar name={`C M`} type={[`green`, `yellow`]} size={6} className={`neg-mb-6 ml-6`}/></div>
                </div>
            </>}
        </div>
    )
}

const GenericUnauthorized = (props: any) => {

    return (
        <div className={`block w-screen column ${props.isMobile ? 'pt-4' : 'h-screen'}`} data-color={`gray`} style={{overflow: 'hidden'}}>
            <div className={`row left nogap w-flex nowrap ${props.isMobile ? 'pl-3 neg-mb-5' : 'pl-4 h-7 neg-mb-7 min-w-9'}`}>
                <div className={`row left nogap nowrap noscroll osmos-logo`}>
                    <img src={OsmosOBlack} alt={`O`} style={{height: props.isMobile ? 19 : 24}}></img>
                    <img src={OsmosSBlack} alt={`S`} style={{height: props.isMobile ? 30 : 38}}></img>
                    <img src={OsmosMBlack} alt={`M`} style={{height: props.isMobile ? 19 : 24}}></img>
                    <img src={OsmosOBlack} alt={`O`} style={{height: props.isMobile ? 11 : 14}}></img>
                    <img src={OsmosSBlack} alt={`S`} style={{height: props.isMobile ? 7 : 8}}></img>
                </div>
                <div className={`block mx-${props.isMobile ? 1 : 2}`} data-color={`black-5-opacity`} style={{width: 1, height: props.isMobile ? 30 : 44}}></div>
                <p data-size={6} className={`bold narrow`}>
                    {`Meet the right crowd`}<br/>
                    {`Get things done.`}
                </p>
            </div>

            {props.isMobile ? <div className={`h-screen column w-flex`}>
                <div className={`w-flex h-9 neg-mb-9`}>
                    <div className={`w-flex row right pr-4 pt-5`}>
                        <NewAvatar type={['light-blue', 'blue']} name={`F V`} size={5}/></div>
                    <div className={`w-flex neg-ml-5`}>
                        <NewAvatar type={['pink', 'coral']} name={`F G`} size={6} className={`neg-ml-3`}/></div>
                    <div className={`w-flex row right`}>
                        <NewAvatar type={['light-pink', 'blue']} name={`I G`} size={6} className={`neg-mr-5`}/></div>
                </div>
                
                <div className={`w-flex px-3 column`} style={{zIndex: 1}}>
                    <h3 className={`mt-3 center`}>{`Sorry`}</h3>
                    <p data-size={4} className={`center mt-1 mb-3`}>
                        {`Authorization problem occurred.`}<br/>{`Please, log in again.`}
                    </p>
                    <button className={`block w-7 centered btn-circled-32 mobile`} data-color={`black`} onClick={() => { LogOut() }}>
                        <p data-color={`white`}>{`Log in`}</p>
                    </button>
                </div>

                <div className={`w-flex column bottom h-9 neg-mt-9`}>
                    <div className={`w-flex pl-6 pb-5`}>
                        <NewAvatar type={['white', 'blue']} name={`R K`} size={5} className={`neg-mb-2`}/></div>
                    <div className={`w-flex row right pr-4`}>
                        <NewAvatar type={['light-pink', 'green']} name={`W H`} size={5}/></div>
                    <div className={`w-flex neg-mb-5 pl-3`}>
                        <NewAvatar type={['white', 'yellow']} name={`V N`} size={6}/></div>
                </div>
            </div> : <>
                <div className={`row h-10 neg-mb-10 w-flex nowrap nogap`}>
                    <div className={`w-flex`}>
                        <NewAvatar name={`T N`} type={[`white`, `pink`]} size={6} className={`mb-8 neg-ml-4`}/></div>
                    <div className={`w-flex row center`}>
                        <NewAvatar name={`F W`} type={[`dark-gray`, `coral`]} size={5} className={`mb-8 mr-6`}/></div>
                    <div className={`w-flex h-10 row top right`}>
                        <NewAvatar name={`M G`} type={[`pink`, `green`]} size={6} className={`neg-mt-4 mr-5`}/></div>
                    <div className={`h-9`}>
                        <NewAvatar name={`R K`} type={[`white`, `blue`]} size={5} className={`mb-7`}/></div>
                    <div className={`h-10 row bottom`}>
                        <NewAvatar name={`R K`} type={[`yellow`, `black`]} size={5} className={`mb-7 mr-2`}/></div>
                </div>

                <div className={'column center h-flex'} style={{zIndex: 1}}>
                    <h2 className="mt-3">{`Sorry`}</h2>
                    <p data-size={3} className={`center mt-2`}>
                        {`Authorization problem occurred.`}<br/>
                        {`Please, log in again.`}
                    </p>
                    <div className={`row center mt-4`}>
                        <button className={`block w-8 centered btn-circled-40 desktop`} data-color={`black`} onClick={() => { LogOut() }}>
                            <p data-color={`white`}>{`Log in`}</p>
                        </button>
                    </div>
                </div>
                
                <div className={`row left h-10 neg-mt-10 w-flex nogap nowrap`}>
                    <div className={`w-flex row center`}>
                        <NewAvatar name={`W H`} type={[`light-pink`, `coral`]} size={5} className={`mt-8`}/></div>
                    <div className={`w-flex h-10 row bottom left`}>
                        <NewAvatar name={`S P`} type={[`light-blue`, `blue`]} size={6} className={`neg-mb-5`}/></div>
                    <div className={`w-flex h-9 row bottom`}>
                        <NewAvatar name={`B D`} type={[`light-blue`, `coral`]} size={5} className={`ml-6`}/></div>
                    <div className={`w-flex h-9 row bottom left`}>
                        <NewAvatar name={`C M`} type={[`green`, `yellow`]} size={6} className={`neg-mb-6 ml-6`}/></div>
                </div>
            </>}
        </div>
    )
}

export {
     GenericForbidden,
     GenericUnauthorized,
};